<template>
    <div class="time_list mobile_time_list">
        
        <div 
            v-for="item in timeList.results"
            :key="item.id"
            :style="`grid-template-columns: ${pageConfig.gridColumns};`"
            class="item">
            <div 
                v-for="col in pageConfig.tableInfo" 
                :key="col.field"
                :class="col.class ? col.class : ''"
                class="item_field">
                <template v-if="col.field === 'work_type'">
                    <template v-if="item.work_type">
                        <div class="item_field">
                            <span class="item_head">
                                {{ col.headerName }}:
                            </span>
                            <span>
                                {{ item.work_type.name }}
                            </span>
                        </div>
                    </template>
                </template>
                <template v-if="col.field === 'description'">
                    <div class="item_field">
                        <div v-if="item.description">
                            <span class="item_head">
                                {{ col.headerName }}:
                            </span>
                            <div class="mt-1">
                                {{ item.description }}
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="col.field === 'author'">
                    <div class="item_field">
                        <span class="item_head">
                            {{ col.headerName }}:
                        </span>
                        <span>
                            <Profiler 
                                :user="item.author"
                                :showAvatar="false" />
                        </span>
                    </div>
                </template>
                <template v-if="col.field === 'hours'" class="item_field">
                    <span class="item_head">
                        {{ col.headerName }}:
                    </span>
                    <span>
                        {{ item.hours }}
                    </span>
                </template>
                <template v-if="col.field === 'measure_unit'" class="item_field">
                    <span class="item_head">
                        {{ col.headerName }}:
                    </span>
                    <span v-if="item.measure_unit && item.measure_unit.name">
                        {{ item.measure_unit.name }}
                    </span>
                </template>
                <template v-if="col.field === 'date'" class="item_field">
                    <template v-if="item.date">
                        <span class="item_head">
                            {{ col.headerName }}:
                        </span>
                        <span>
                            {{ $moment(item.date).format('DD.MM.YYYY') }}
                        </span>
                    </template>
                </template>
                <template v-if="col.field === 'actions'" class="item_field">
                    <a-dropdown 
                        class="mt-2"
                        v-if="pageConfig.listActions && user && item.author && user.id === item.author.id" 
                        :getPopupContainer="getPopupContainer2">
                        <a-button
                            type="default" 
                            block
                            icon="menu" />
                        <a-menu slot="overlay">
                            <a-menu-item 
                                v-if="pageConfig.listActions.editAction" 
                                @click="$emit('editTime', item)">
                                {{ $t('task.edit') }}
                            </a-menu-item>
                            <template v-if="pageConfig.listActions.deleteAction" >
                                <a-menu-divider />
                                <a-menu-item
                                    class="text_red" 
                                    @click="$emit('deleteTime', item)">
                                    {{ $t('task.remove') }}
                                </a-menu-item>
                            </template>
                        </a-menu>
                    </a-dropdown>
                </template>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        pageConfig: {
            type: Object,
            required: true
        },
        timeList: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        task: {
            type: Object,
            required: true
        }
    },
    methods: {
        getPopupContainer() {
            return document.querySelector('.work_time_form')
        },
        getPopupContainer2() {
            return document.querySelector('.task_body_wrap')
        },
        descSubstr(text) {
            if(text && text.length > 60)
                return text.substr(0, 60) + '...'
            else
                return text
        },
        descLength(text) {
            if(text && text.length > 60)
                return true
            else
                return false
        },
    }
}
</script>

<style lang="scss">
.mobile_time_list {
    .item {
        &:not(:last-child) {
            margin-bottom: 1em;
            border-bottom: 1px solid var(--borderColor);
        }
    }
    .item_field {
        margin-bottom: 0.2em;
    }
    .item_head {
            margin-bottom: 0.3em;   
    }
}
</style>